<script lang="ts">
// use normal <script> to declare options
export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
const handleBackdropClick = (e: Event) => {
  props.static || emit('update:show', false);
};

// Add one overflow class for each of the modals
const handleBeforeEnter = () => {
  const bodyPr =
    document.body.offsetHeight <= innerHeight ? 0 : getScrollbarWidth();
  // document.body.classList.value += ' overflow-hidden';
  document.body.style.setProperty('--body-pr', `${bodyPr}px`);
};

const handleAfterLeave = () => {
  // restoreBodyOverflow();
  emit('closed');
};

const getScrollbarWidth = () => {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.parentNode?.removeChild(outer);

  return scrollbarWidth;
};

// Remove overflow class for the current modal
const restoreBodyOverflow = () =>
  (document.body.classList.value = document.body.classList.value.replace(
    ' overflow-hidden',
    ''
  ));

const props = withDefaults(
  defineProps<{ show: boolean; size?: string; static?: boolean }>(),
  { size: '500px' }
);
const emit = defineEmits<{
  (e: 'update:show', show: boolean): void;
  (e: 'closed'): void;
}>();

// Restore the overflow if it's still showing
onBeforeUnmount(() => props.show && restoreBodyOverflow());
</script>

<template>
  <Transition
    name="modal"
    appear
    @before-enter="handleBeforeEnter"
    @after-leave="handleAfterLeave"
  >
    <div
      v-if="show"
      class="sticky bottom-40 inset-0 z-3 transition-opacity duration-300 mb-40"
      tabindex="-1"
      @click.self="handleBackdropClick"
    >
      <div
        class="container flex justify-center"
        @click.self="handleBackdropClick"
      >
        <div
          class="modal-content max-w-full p-24 lg:py-32 transition-transform duration-300"
          v-bind="$attrs"
        >
          <slot />
        </div>
      </div>

      <slot name="after" />
    </div>
  </Transition>
</template>

<style scoped lang="postcss">
.modal-content {
  width: v-bind(size);
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;

  .modal-content {
    transform: scale(0.95);
  }
}
</style>
